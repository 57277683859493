import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Config from "../../config"
import ContentWrapper from "../styles/ContentWrapper"
import Logo from "./logo"
import Button from "../styles/Button"

const { footerLinks } = Config

const StyledFooter = styled.footer`
  width: 100%;
  height: ${({ theme }) => theme.footerHeight};
  background: ${({ theme }) => theme.colors.primary};
  margin-top: 10rem;
  
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer-links {
      /* Adjust width of links wrapper accordingly */
      display: flex;
      align-items: center;
      justify-self: flex-end;

      button, a {
        font-size: 0.8rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #ffffff;
        letter-spacing: 1px;
        padding: 16px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {

      }
    }
  }
`

const Footer = () => (
  <StyledFooter>
    <StyledContentWrapper>
      <Link to="/" aria-label="home" onClick={scrollToTop()}>
        <Logo color="white" size="1.5rem" />
      </Link>
      <div className="footer-links">
        <a href="mailto:nick.hart.dev@gmail.com" target="_blank">
          Contact
        </a>
        <Link to="/" aria-label="home" onClick={scrollToTop()}>Back to Top</Link>
      </div>
      {/* <div className="footer-links">
        {footerLinks.map(({ name, url }, key) => (
          <StyledLink key={key} to={url}>{name}</StyledLink>
        ))}
      </div> */}
    </StyledContentWrapper>
  </StyledFooter>
)

const scrollToTop = () => {
  return () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}

export default Footer
